import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import backgroundImage from './assets/images/cellphone.png';
import loadingGif from './assets/images/loading.gif';

// Estilo global básico
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body, html {
    width: 100%;
    height: 100%;
    font-family: Arial, sans-serif;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  #terminal-messages::-webkit-scrollbar {
    width: 6px;
  }

  #terminal-messages::-webkit-scrollbar-thumb {
    background-color: #48bb7e;
    border-radius: 99px;
  }

  #terminal-messages::-webkit-scrollbar-track {
    background-color: black;
  }
`;

// Animações
const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

// Contêiner principal centralizado
const MainContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CellphoneImage = styled.div`
  position: absolute;
  width: 1920px;
  height: 1080px;
  background: url(${backgroundImage}) no-repeat center center;
  background-size: contain;
  z-index: 3;
  pointer-events: none;
`;

const BackgroundGif = styled.div`
  width: 340px;
  height: 448px;
  background: url(${loadingGif}) no-repeat center center;
  background-size: contain;
  position: absolute;
  z-index: 2;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  animation: ${(props) => (props.isVisible ? '' : fadeOut)} 1s ease-out forwards;
  transform: translateY(-64px);
`;

const TerminalContainer = styled.div`
  width: 340px;
  height: 448px;
  background: black;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  color: #51d38e;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  animation: ${(props) => (props.isVisible ? fadeIn : '')} 1s ease-in forwards;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  position: absolute;
  z-index: 1;
  transform: translateY(-64px);
`;

const StatusBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between; /* Isso garante que o espaço será distribuído entre os itens */
  align-items: center;
  color: #51d38e;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
  margin-bottom: 10px;
`;

const Battery = styled.div`
  margin-right: 10px;
  font-size: 16px;
`;

const Time = styled.div`
  margin-left: auto;  /* Isso move a hora para o lado direito */
  font-size: 16px;
`;

const TerminalMessages = styled.div`
  flex: 1;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 10px;
  scroll-behavior: smooth;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 8px;
  height: 40px;
  gap: 8px;
`;

const TerminalInput = styled.input`
  flex: 1;
  border: none;
  background: black;
  color: #51d38e;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  padding: 5px;
  outline: none;
`;

const SendButton = styled.button`
  background-color: #48bb7e;
  color: #000;
  border: none;
  padding: 3px 10px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  cursor: pointer;
`;

function App() {
  const [showGif, setShowGif] = useState(true);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isTyping, setIsTyping] = useState(false); // Estado para controlar a digitação de Pump AI
  const [isTerminalVisible, setIsTerminalVisible] = useState(false);
  const [time, setTime] = useState(new Date().toLocaleTimeString());

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowGif(false);
    }, 9000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Atualiza a hora a cada segundo
    const interval = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(interval); // Limpa o intervalo quando o componente for desmontado
  }, []);

  useEffect(() => {
    // Quando o gif desaparecer, mostramos o terminal
    if (!showGif) {
      setIsTerminalVisible(true);
    }
  }, [showGif]);

  useEffect(() => {
    if (!isTerminalVisible) return;

    const initialMessages = [
      'Welcome to the PumpPhone Terminal!',
      'Type a command below:',
      'Available commands:',
      '-x: Go to Twitter',
      '-telegram: Go to Telegram',
      '-contract: Go to PumpFun',
      '',
      'For help, type help.',
    ];

    setMessages(initialMessages.map(msg => ({ sender: 'System', text: msg })));
  }, [isTerminalVisible]);

  // Função para fazer o ChatGPT "falar" em áudio
   const speak = (text) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = 'en-US';
      utterance.rate = 1;
      utterance.pitch = 1;
      window.speechSynthesis.speak(utterance);
    } else {
      console.error('Speech synthesis is not supported in this browser.');
    }
  };

  const handleSendMessage = async () => {
    if (!inputValue.trim()) return;

    const userMessage = inputValue.trim();
    setMessages((prev) => [...prev, { sender: 'You', text: userMessage }]);
    setInputValue('');
    
    // Ativa a mensagem de "Pump AI is typing..."
    setIsTyping(true);

    try {
      // Simula o tempo de "digitação"
      setTimeout(async () => {
        // Chama a API do ChatGPT
        const response = await fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            model: 'gpt-3.5-turbo',
            messages: [{ role: 'user', content: userMessage }],
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const botMessage = data.choices[0].message.content;

        // Atualiza a lista de mensagens com a resposta do bot
        setMessages((prev) => [...prev, { sender: 'Pump AI', text: botMessage }]);
        speak(botMessage); // Faz o ChatGPT falar a resposta

        // Desativa a mensagem de digitação e exibe a resposta
        setIsTyping(false);
      }, 2000); // Simula 2 segundos de "digitação"
    } catch (error) {
      console.error('Error communicating with ChatGPT:', error);
      setMessages((prev) => [
        ...prev,
        { sender: 'Pump AI', text: 'Error communicating with ChatGPT.' },
      ]);
      setIsTyping(false);
    }
  };

  const handleCommand = (command) => {
    if (!command.trim()) return;

    // Verifica se o comando é válido
    switch (command.trim().toLowerCase()) {
      case '-x':
        window.open('https://x.com/pumpphone', '_blank');
        break;
      case '-telegram':
        window.open('https://t.me/pumpphone', '_blank');
        break;
      case '-contract':
        window.open('https://pump.fun/coin/J51pJxBeUPS4vFD8822YncsFLPsuCDCLacsNhbzRpump', '_blank');
        break;
      default:
        // Se não for um comando válido, envia para o ChatGPT
        handleSendMessage();
        break;
    }
  };

  return (
    <>
      <GlobalStyle />
      <MainContainer>
        <CellphoneImage />
        {showGif ? (
          <BackgroundGif isVisible={showGif} />
        ) : (
          <TerminalContainer isVisible={!showGif}>
            <StatusBar>
              <Battery>87%</Battery>
              <Time>{time}</Time>
            </StatusBar>
            <TerminalMessages id="terminal-messages">
              {messages.map((msg, index) => (
                <div
                  key={index}
                  style={{ color: msg.sender === 'You' ? 'white' : '#51d38e' }}
                >
                  <strong>{msg.sender}:</strong> {msg.text}
                </div>
              ))}
              {isTyping && (
                <div style={{ color: '#51d38e', fontStyle: 'italic' }}>
                  <strong>Pump AI:</strong> is typing...
                </div>
              )}
            </TerminalMessages>
            <InputContainer>
              <TerminalInput
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleCommand(inputValue);
                  }
                }}
                placeholder="Type a command..."
                autoFocus
              />
              <SendButton onClick={() => handleCommand(inputValue)}>
                Execute
              </SendButton>
            </InputContainer>
          </TerminalContainer>
        )}
      </MainContainer>
    </>
  );
}

export default App;
